import {productDetailsSettings, ProductDetailsSettings} from '../../../bps/components/productDetails/productDetailsSettings';

productDetailsSettings.referredByHash = '';
productDetailsSettings.productReview.useRecommendationSection = false;
productDetailsSettings.flags.minusText = '<span class="minus">-</span>';
productDetailsSettings.flags.percentText = '<span class="percent"></span>';
productDetailsSettings.flags.whitelist = ['savings', 'personalized', 'free', 'award', 'new', 'newVintage', 'stoerer', 'exclusiveText', 'bio', 'boxSetUrl']
productDetailsSettings.flags.whitelistTag = ['vegan'];

export {productDetailsSettings, ProductDetailsSettings};
